import axios from "axios";

const { REACT_APP_API, REACT_APP_XToken } = process.env;
// axios 내용
const axiosConfig = {
  baseURL: REACT_APP_API,
};

console.log(REACT_APP_API);

const configureAxios = () => {
  // axios 인스턴스 생성
  axios.defaults.baseURL = REACT_APP_API;

  // request interceptors 설정
  axios.interceptors.request.use(
    async (req) => {
      // headers의 authorization에 accessToken을 담는 곳
      const accessToken = sessionStorage.getItem("token");
      req.headers = {
        "Content-Type": "multipart/form-data",
        accept: "application/json;charset=UTF-8",
        "x-token": `FJKD39FJK$8`,
      };

      return req;
    },
    async (err) => {
      return Promise.reject(err);
    }
  );

  // response interceptors 설정
  axios.interceptors.response.use(
    (res) => {
      // 토큰 만료 시 처리하는 곳. 보통은 백에서 처리해줌.
      return res;
    },
    async (err) => {
      return Promise.reject(err);
    }
  );
};

export default configureAxios;
