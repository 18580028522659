import AuthRoute from "componenet/Guard/AuthRoute";
import GuestRoute from "componenet/Guard/GuestRoute";
import Error404 from "pages/Error404";
import React, { Fragment, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

/**
 * @param Routes 여러 Route를 감싸서 그 중 규칙이 일치하는 라우트 단 하나만을 렌더링 시켜주는 역할
 * @param Route path속성에 경로, element속성에는 컴포넌트
 */
const routesConfig = [
  {
    path: "/",
    element: () => <Navigate replace to="/dashboard" />,
  },
  {
    path: "/404",
    element: Error404,
  },
  {
    label: "로그인",
    path: "/login",
    element: lazy(() => import("pages/Login/Login")),
  },
  {
    label: "대시보드",
    path: "/*",
    routes: [
      {
        label: "대시보드",
        path: "/dashboard",
        element: lazy(() => import("pages/Main/Main")),
      },
      {
        label: "CRM",
        path: "/crm",
        element: lazy(() => import("pages/CRM/GeneralMember")),
      },
      {
        label: "회원관리 - 관리자",
        path: "/crm/app-push",
        element: lazy(() => import("pages/CRM/AdminMember")),
      },
      {
        label: "기준관리",
        path: "/standard",
        element: lazy(() => import("pages/Standard/GeneralMember")),
      },
      {
        label: "상품관리",
        path: "/goods",
        element: lazy(() => import("pages/Goods/GeneralMember")),
      },
      {
        label: "상품관리",
        path: "/goods/list",
        element: lazy(() => import("pages/Goods/GeneralMember")),
      },
      {
        label: "상품관리",
        path: "/goods/keywords",
        element: lazy(() => import("pages/Goods/GeneralMember")),
      },
      {
        label: "상품관리",
        path: "/goods/common",
        element: lazy(() => import("pages/Goods/GeneralMember")),
      },
      {
        label: "주문관리",
        path: "/order",
        element: lazy(() => import("pages/Order/GeneralMember")),
      },
      {
        label: "주문관리",
        path: "/order/menu",
        element: lazy(() => import("pages/Order/GeneralMember")),
      },
      {
        label: "주문관리",
        path: "/order/cencle",
        element: lazy(() => import("pages/Order/GeneralMember")),
      },
      {
        label: "주문관리",
        path: "/order/talk",
        element: lazy(() => import("pages/Order/GeneralMember")),
      },

      {
        label: "프로모션 관리",
        path: "/promotions",
        element: lazy(() => import("pages/Promotion/GeneralMember")),
      },
      {
        label: "프로모션 관리",
        path: "/promotions/wishlist",
        element: lazy(() => import("pages/Promotion/GeneralMember")),
      },
      {
        label: "프로모션 관리",
        path: "/promotions/offline",
        element: lazy(() => import("pages/Promotion/GeneralMember")),
      },
      {
        label: "프로모션 관리",
        path: "/promotions/presentation",
        element: lazy(() => import("pages/Promotion/GeneralMember")),
      },
      {
        label: "프로모션 관리",
        path: "/promotions/timesale",
        element: lazy(() => import("pages/Promotion/GeneralMember")),
      },
      {
        label: "프로모션 관리",
        path: "/promotions/event",
        element: lazy(() => import("pages/Promotion/GeneralMember")),
      },
      {
        label: "프로모션 관리",
        path: "/promotions/gifts",
        element: lazy(() => import("pages/Promotion/GeneralMember")),
      },
      {
        label: "프로모션 관리",
        path: "/promotions/gifts/address",
        element: lazy(() => import("pages/Promotion/GeneralMember")),
      },

      {
        label: "BBS",
        path: "/bbs",
        element: lazy(() => import("pages/BBS/GeneralMember")),
      },
      {
        label: "컨텐츠 관리",
        path: "/contents",
        element: lazy(() => import("pages/Contents/GeneralMember")),
      },
      {
        label: "환경설정",
        path: "/setting",
        element: lazy(() => import("pages/Setting/GeneralMember")),
      },

      {
        label: "대시보드",
        path: "/dashboard",
        element: lazy(() => import("pages/Main/Main")),
      },
      {
        label: "어플관리",
        path: "/application",
        element: lazy(() => import("pages/Application")),
      },
      {
        label: "팝업 생성",
        path: "/application/register",
        element: lazy(() => import("pages/Application/Popup/PopupRegist")),
      },
      {
        label: "팝업 상세",
        path: "/application/detail/:id",
        element: lazy(() => import("pages/Application/Popup/PopupRegist")),
      },
      {
        label: "프로모션",
        path: "/application/promotion",
        element: lazy(() => import("pages/Application/Promotion")),
      },
      {
        label: "프로모션 생성",
        path: "/application/promotion/register",
        element: lazy(() =>
          import("pages/Application/Promotion/PromotionRegist")
        ),
      },
      {
        label: "프로모션 상세",
        path: "/application/promotion/detail/:id",
        element: lazy(() =>
          import("pages/Application/Promotion/PromotionDetail")
        ),
      },
      {
        label: "프로모션2",
        path: "/application/promotion2",
        element: lazy(() => import("pages/Application/Promotion2")),
      },
      {
        label: "프로모션2 생성",
        path: "/application/promotion2/register",
        element: lazy(() =>
          import("pages/Application/Promotion2/PromotionRegist")
        ),
      },
      {
        label: "프로모션2 상세",
        path: "/application/promotion2/detail/:id",
        element: lazy(() =>
          import("pages/Application/Promotion2/PromotionDetail")
        ),
      },

      {
        label: "배너",
        path: "/application/banner",
        element: lazy(() => import("pages/Application/Banner")),
      },
      {
        label: "팝업 띠배너 관리",
        path: "/application/banner2",
        element: lazy(() => import("pages/Application/Banner2")),
      },
      {
        label: "인기차량 관리",
        path: "/application/popular-vehicle",
        element: lazy(() => import("pages/Application/PopularVehicle")),
      },
      {
        label: "차량 선택사유 관리",
        path: "/application/vehicle-recommend",
        element: lazy(() => import("pages/Application/VehicleRecommend")),
      },
      {
        label: "차량 선택사유 관리 생성",
        path: "/application/vehicle-recommend/register",
        element: lazy(() =>
          import("pages/Application/VehicleRecommend/VehicleRecommendRegist")
        ),
      },
      {
        label: "차량 추천 관리",
        path: "/application/vehicle-selection",
        element: lazy(() => import("pages/Application/VehicleSelection")),
      },
      {
        label: "회원관리 - 일반",
        path: "/member-management/general",
        element: lazy(() => import("pages/Member/GeneralMember")),
      },
      {
        label: "회원관리 - 일반 상세",
        path: "/member-management/general/detail/:id",
        element: lazy(() => import("pages/Member/GeneralMemberDetail")),
      },
      {
        label: "회원관리 - 관리자",
        path: "/member-management/admin",
        element: lazy(() => import("pages/Member/AdminMember")),
      },
      {
        label: "회원관리 - 관리자 상세",
        path: "/member-management/admin/detail/:id",
        element: lazy(() => import("pages/Member/AdminMemberDetail")),
      },
      {
        label: "회원관리 - 관리자 생성",
        path: "/member-management/admin/register",
        element: lazy(() => import("pages/Member/AdminMemberAdd")),
      },
      {
        label: "영업관리 - 견적",
        path: "/sales-management",
        element: lazy(() => import("pages/Sales-Management")),
      },
      {
        label: "영업관리 - 견적 상세",
        path: "/sales-management/estimation/:id",
        element: lazy(() => import("pages/Sales-Management/Estimation/Detail")),
      },
      {
        label: "영업관리 - 서류관리",
        path: "/sales-management/document",
        element: lazy(() => import("pages/Sales-Management/Document")),
      },
      {
        label: "영업관리 - 서류관리 상세",
        path: "/sales-management/document/:id",
        element: lazy(() => import("pages/Sales-Management/Document/Detail")),
      },
      {
        label: "영업관리 - 비대면 심사",
        path: "/sales-management/audit",
        element: lazy(() => import("pages/Sales-Management/Audit")),
      },
      {
        label: "영업관리 - 비대면 상세",
        path: "/sales-management/audit/:id",
        element: lazy(() => import("pages/Sales-Management/Audit/Detail")),
      },
      {
        label: "영업관리 - 계약관리",
        path: "/sales-management/contract",
        element: lazy(() => import("pages/Sales-Management/Contract")),
      },
      {
        label: "영업관리 - 계약관리 상세",
        path: "/sales-management/contract/:id",
        element: lazy(() => import("pages/Sales-Management/Contract/Detail")),
      },
      {
        label: "영업관리 - 출차관리",
        path: "/sales-management/carout",
        element: lazy(() => import("pages/Sales-Management/Carout")),
      },
      {
        label: "영업관리 - 출차관리 상세",
        path: "/sales-management/carout/:id",
        element: lazy(() => import("pages/Sales-Management/Carout/Detail")),
      },
      {
        label: "영업관리 - 상담관리",
        path: "/sales-management/counsel",
        element: lazy(() => import("pages/Sales-Management/Counsel")),
      },
      {
        label: "영업관리 - 상담관리",
        path: "/sales-management/counsel/:id",
        element: lazy(() => import("pages/BoardManagement/Inquiry/Inquiry")),
      },
      {
        label: "게시판관리 - 1:1 문의",
        path: "/boardManagement/inquiry",
        element: lazy(() => import("pages/BoardManagement/Inquiry/Inquiry")),
      },
      {
        label: "게시판관리 - 1:1 문의 상세",
        path: "/boardManagement/inquiry/detail/:id",
        element: lazy(() =>
          import("pages/BoardManagement/Inquiry/InquiryDetail")
        ),
      },
      {
        label: "게시판관리 - 1:1 문의 인사말 관리",
        path: "/boardManagement/inquiry2",
        element: lazy(() => import("pages/BoardManagement/Inquiry2/Inquiry")),
      },
      {
        label: "게시판관리 - 1:1 문의 인사말 관리 상세",
        path: "/boardManagement/inquiry2/detail/:id",
        element: lazy(() =>
          import("pages/BoardManagement/Inquiry2/InquiryDetail")
        ),
      },
      {
        label: "게시판관리 - 자주하는 질문",
        path: "/boardManagement/questions",
        element: lazy(() =>
          import("pages/BoardManagement/Questions/Questions")
        ),
      },
      {
        label: "게시판관리 - 자주하는 질문 생성",
        path: "/boardManagement/questions/register",
        element: lazy(() =>
          import("pages/BoardManagement/Questions/QuestionsRegister")
        ),
      },
      {
        label: "게시판관리 - 자주하는 질문 수정",
        path: "/boardManagement/questions/edit/:id",
        element: lazy(() =>
          import("pages/BoardManagement/Questions/QuestionsRegister")
        ),
      },
      {
        label: "게시판관리 - 자주하는 질문 디테일",
        path: "/boardManagement/questions/detail/:id",
        element: lazy(() =>
          import("pages/BoardManagement/Questions/QuestionsDetail")
        ),
      },
      {
        label: "게시판관리 - 공지사항",
        path: "/boardManagement/notice",
        element: lazy(() => import("pages/BoardManagement/Notice/Notice")),
      },
      {
        label: "게시판관리 - 공지사항 생성",
        path: "/boardManagement/notice/register",
        element: lazy(() =>
          import("pages/BoardManagement/Notice/NoticeRegister")
        ),
      },
      {
        label: "게시판관리 - 공지사항 수정",
        path: "/boardManagement/notice/edit/:id",
        element: lazy(() =>
          import("pages/BoardManagement/Notice/NoticeRegister")
        ),
      },
      {
        label: "게시판관리 - 공지사항 상세",
        path: "/boardManagement/notice/detail/:id",
        element: lazy(() =>
          import("pages/BoardManagement/Notice/NoticeDetail")
        ),
      },
      {
        label: "게시판관리 - FAQ ",
        path: "/boardManagement/notice2",
        element: lazy(() => import("pages/BoardManagement/Notice2/Notice")),
      },
      {
        label: "게시판관리 - FAQ 생성",
        path: "/boardManagement/notice/register2",
        element: lazy(() =>
          import("pages/BoardManagement/Notice2/NoticeRegister")
        ),
      },
      {
        label: "게시판관리 - FAQ 수정",
        path: "/boardManagement/notice2/edit/:id",
        element: lazy(() =>
          import("pages/BoardManagement/Notice2/NoticeRegister")
        ),
      },
      {
        label: "게시판관리 - FAQ 상세",
        path: "/boardManagement/notice2/detail/:id",
        element: lazy(() =>
          import("pages/BoardManagement/Notice2/NoticeDetail")
        ),
      },
      {
        label: "게시판관리 - 꿀팁",
        path: "/boardManagement/tips",
        element: lazy(() => import("pages/BoardManagement/Tips/Tips")),
      },
      {
        label: "게시판관리 - 꿀팁 생성",
        path: "/boardManagement/tips/register",
        element: lazy(() => import("pages/BoardManagement/Tips/TipsRegister")),
      },
      {
        label: "게시판관리 - 꿀팁 수정",
        path: "/boardManagement/tips/edit/:id",
        element: lazy(() => import("pages/BoardManagement/Tips/TipsRegister")),
      },
      {
        label: "게시판관리 - 꿀팁 상세",
        path: "/boardManagement/tips/detail/:id",
        element: lazy(() => import("pages/BoardManagement/Tips/TipsDetail")),
      },
      {
        label: "게시판관리 - 뉴스레터",
        path: "/newsLetter",
        element: lazy(() =>
          import("pages/BoardManagement/NewsLetter/NewsLetter")
        ),
      },
      {
        label: "게시판관리 - 뉴스레터 생성",
        path: "/boardManagement/newsLetter/register",
        element: lazy(() =>
          import("pages/BoardManagement/NewsLetter/NewLetterRegister")
        ),
      },
      {
        label: "게시판관리 - 뉴스레터 수정",
        path: "/boardManagement/newsLetter/edit/:id",
        element: lazy(() =>
          import("pages/BoardManagement/NewsLetter/NewLetterRegister")
        ),
      },
      {
        label: "게시판관리 - 뉴스레터 상세",
        path: "/boardManagement/newsLetter/detail/:id",
        element: lazy(() =>
          import("pages/BoardManagement/NewsLetter/NewsLetterDetail")
        ),
      },
    ],
  },
];

const renderRoutes = (routes) => (
  <Routes>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Element = route.element;
      const innerRoutes = route.routes;
      return (
        <Route
          key={i}
          path={route.path}
          element={
            <Guard>
              {innerRoutes ? renderRoutes(innerRoutes) : <Element />}
            </Guard>
          }
        />
      );
    })}
  </Routes>
);

const RoutesHandler = () => {
  return renderRoutes(routesConfig);
};

export default RoutesHandler;
