import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// createSlice를 사용하면 따르 액션타입을 정의하지 않아도 자동으로 액션타입을 만들어준다.
export const authSlice = createSlice({
  // 액션 타입에 사용되는 이름.
  name: "auth",
  // reducer 초기 상태 값.
  initialState: {
    login: undefined,
    permission: undefined,
  },
  // reducer 맵. 키값(setUserName)은 작업을 생성하는데 사용.
  reducers: {
    // 리듀서 액션함수
    // action.type = 'auth/setUserName', action.payload = { userName: "xxx" }
    setLogin: (state, action) => {
      state.login = action.payload;
      window.sessionStorage.setItem("login", JSON.stringify(action.payload));
    },
    clearLogin: (state) => {
      window.sessionStorage.clear();
      state.login = undefined;
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
  },
});

// action creator함수를 활용하기 위해 export
export const { setLogin, clearLogin, setPermission } = authSlice.actions;

export default authSlice.reducer;
