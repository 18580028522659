import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import store from "data";
import App from "./App";
import "assets/styles/common.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Spiner from "utils/Spiner";
import configureAxios from "api/configureAxios";

/**
 * @param Provider redux 스토어에 접근하기 위해 Provider로 등록.
 * @param BrowserRouter BrowserRouter는 HTML5의 History API를 사용하여 URL과 UI를 동기해주는 Router이다.
 */

configureAxios();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      useErrorBoundary: true,
      select: ({ data }) => data?.result || data,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Spiner />}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </QueryClientProvider>
  </Suspense>
);
