import React from "react";
import Routes from "Routes";

/**
 * @param Routes 여러 Route를 감싸서 그 중 규칙이 일치하는 라우트 단 하나만을 렌더링 시켜주는 역할
 * @param Route path속성에 경로, element속성에는 컴포넌트
 */

const App = () => {
  return <Routes />;
};

export default App;
