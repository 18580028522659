import React from "react";

function Error404() {
  return (
    <>
      <h1>Error404</h1>
    </>
  );
}

export default Error404;
